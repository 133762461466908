@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/lexend-deca-v21-latin-100.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/lexend-deca-v21-latin-200.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lexend-deca-v21-latin-300.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lexend-deca-v21-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/lexend-deca-v21-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/lexend-deca-v21-latin-600.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lexend-deca-v21-latin-700.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/lexend-deca-v21-latin-800.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lexend-deca-v21-latin-900.woff2') format('woff2');
}

@font-face {
  font-family: 'paxicon';
  src: url('../fonts/paxicon.eot?81500578');
  src: url('../fonts/paxicon.eot?81500578#iefix') format('embedded-opentype'),
       url('../fonts/paxicon.woff2?81500578') format('woff2'),
       url('../fonts/paxicon.woff?81500578') format('woff'),
       url('../fonts/paxicon.ttf?81500578') format('truetype'),
       url('../fonts/paxicon.svg?81500578#paxicon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'paxicon';
    src: url('../font/paxicon.svg?81500578#paxicon') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "paxicon";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-file-invoice-xrechnung:before { content: '\e800'; } /* '' */
.icon-blinds:before { content: '\e801'; } /* '' */
.icon-blinds-raised:before { content: '\e802'; } /* '' */
.icon-blinds-open:before { content: '\e803'; } /* '' */
.icon-folder-check:before { content: '\e804'; } /* '' */
.icon-folder-question:before { content: '\e805'; } /* '' */
.icon-window-frame-open:before { content: '\e806'; } /* '' */
.icon-window-frame:before { content: '\e807'; } /* '' */
.icon-file-invoice-x:before { content: '\e808'; } /* '' */
.icon-rexx:before { content: '\e809'; } /* '' */
.icon-door-config:before { content: '\e80e'; } /* '' */
.icon-window-frame-pax-one:before { content: '\e80f'; } /* '' */
.icon-window-frame-pax-two:before { content: '\e810'; } /* '' */
